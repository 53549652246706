import './App.css';
import React, { Component } from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import web3 from "web3";
import detectEthereumProvider from '@metamask/detect-provider';
import Profile from './component/profile';
import user from './assets/user.png';
import mM from './assets/MetaMask.png';
import Axios from 'axios';
import signature from './assets/signature.png';
import wordsCount from 'words-count';
import rabbit from './assets/rabbit.png';
import logo from './assets/logo.png';
import checked from './assets/checked.png';
import wrong from './assets/sad.png';
// const wordsCount = require('words-count').default;  
var cors = require('cors');


const redirect = () => {
	window.open("http://nboard.belladonnabunny.com");
}

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let maxMintNFTs;
let onlyLeft;
let now;
let myTotalWL;
let tokenIDArray = [];
let tokenIDBool;
let x;
let successMessage;
let walletExist;
let points_table_array;
let votedAddresses = [];
let match = 0;
let eligible = 0;



const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_cost",
				"type": "uint256"
			}
		],
		"name": "setMintRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_limit",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleMintLimit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "onlyLeftValue",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "publicSaleMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleMintLimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0xD4D7Ff7f6d98A06AE768f7C5A5E27887cdbAb026";
let contract;
let wMintAmount = 1;
let totalWhitelistMinted;
let walletBalance;
let wStatus;
let publicSale;
let myNFTBalance;
let gasLimit = 285000;
let whitelistStatus;
let owner;
let tokenID;
let tokenURI;
let imageURI;
let plus;
let minus;
let points = 0;
let db_points;
let writersWallet;
var text;
let alreadySubmitted = false;

function getWalletAddress() {

	// Get the input text value
	writersWallet = document.getElementById("writerWallet1").value;
}

function words() {

	// Get the input text value
	text = document
		.getElementById("w3review").value;

	// Initialize the word counter
	var numWords = 0;

	// Loop through the text
	// and count spaces in it
	for (var i = 0; i < text.length; i++) {
		var currentCharacter = text[i];

		// Check if the character is a space
		if (currentCharacter == " ") {
			numWords += 1;
		}
	}

	// Add 1 to make the count equal to
	// the number of words
	// (count of words = count of spaces + 1)
	numWords += 1;

	// Display it as output
	document.getElementById("show")
		.innerHTML = numWords;
}

function countWords(self) {
	try {

		var spaces = self.value.match("/\S+/g");
		var words = spaces ? spaces.length : 0;

		document.getElementById("words-counter").innerHTML = words + " words";

	} catch (err) {
		console.log(err);

	}

}

class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "",
		maxmint: '',
		stateNow: '',
		totalWhitelistSupply: '',
		myTotalWLNFTs: '',
		accountBalance: '',
		wlStatus: '',
		wlEvent: false,
		myNFTWallet: '',
		priceDisplay: '',
		tokenIDDisplay: '',
		tokenURIDisplay: '',
		imageURIDisplay: '',
		notice_board_Array: [],
		state_img_url: '',
		_plus: '',
		_minus: '',
		_points: 0,
		_db_points: '',
		_walletList: '',
		_text: '',
		_successMessage: '',
		_walletExist: '',
		_alreadySubmitted: false,
		points_table_Array: [],
		_votedAddresses: [],
		_eligible: 0

	}



	closeWindow = async event => {
		event.preventDefault();

		successMessage = 0;
		this.setState({ _successMessage: successMessage });
		console.log("status" + this.state._successMessage);
	}

	walletbtn = async event => {
		event.preventDefault();

		const provider = await detectEthereumProvider();

		if (provider) {

			console.log('Ethereum successfully detected!');

			(async () => {

				const chainId = 1;

				if (window.ethereum.networkVersion !== chainId) {
					try {
						await window.ethereum.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: web3.utils.toHex(chainId) }],
						});
					} catch (err) {
						// This error code indicates that the chain has not been added to MetaMask.
						if (err.code === 4902) {
							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										chainName: 'Ethereum Mainnet',
										chainId: web3.utils.toHex(chainId),
										nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
										rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
									},
								],
							});
						}
					}
				}




				if (window.ethereum) {
					await window.ethereum.send('eth_requestAccounts');
					window.web3 = new web3(window.ethereum);

					contract = new window.web3.eth.Contract(ABI, address);

					let accounts = await window.web3.eth.getAccounts();
					account = accounts[0];
					this.setState({ walletAddress: account });

					console.log("Wallet Address" + this.state.walletAddress);

					for (let x = 0; x < this.state.notice_board_Array.length; x++) {
						console.log(this.state.notice_board_Array[x].wallet_address);

						if (this.state.notice_board_Array[x].wallet_address == account) {

							alreadySubmitted = true;
							this.setState({ _alreadySubmitted: alreadySubmitted });
							console.log("alreadySubmitted : " + alreadySubmitted);

							points = this.state.notice_board_Array[x].points;
							this.setState({ _points: points });
							console.log("points : " + points);

							break;

						} else {

							alreadySubmitted = false;
							this.setState({ _alreadySubmitted: alreadySubmitted });
							console.log("alreadySubmitted : " + alreadySubmitted);

							points = this.state.notice_board_Array[x].points;
							this.setState({ _points: points });
							console.log("points : " + points);
						}

					}

					let index = 0;

					for (let i = 0; i < this.state.points_table_Array.length; i++) {


						console.log(this.state.points_table_Array[i].votingWallet);
						console.log("points_table_Array1 :" + this.state.points_table_Array[0].writersWallet);
						console.log("points_table_Array2 :" + this.state.points_table_Array[0].votingWallet);


						if (this.state.points_table_Array[i].votingWallet == account) {

							votedAddresses[index] = this.state.points_table_Array[i].writersWallet;
							this.setState({ _votedAddresses: votedAddresses });
							index++;
							console.log("votedAddress :" + votedAddresses);
							console.log("votedAddress :" + this.state._votedAddresses);



						}

						console.log("votedAddress2 :" + this.state._votedAddresses);


					}









					try {
						totalSupplyNFT = await contract.methods.totalSupply().call();
						let istokenOwner = await contract.methods.balanceOf(account).call();

						if (istokenOwner > 0) {
							let firsttokenIDOwn = await contract.methods.tokenOfOwnerByIndex(account, 0).call();
							eligible = 1;
							this.setState({ _eligible: eligible });
							console.log("eligible :" + this.state._eligible);

							imageURI = "https://www.belladonnabunny.com/project%20a/reduced/" + firsttokenIDOwn + ".png";
							console.log(imageURI);
							this.setState({ imageURIDisplay: imageURI });

						} else {

							imageURI = "https://belladonnabunny.com/project%20a/male.gif";
							console.log(imageURI);
							this.setState({ imageURIDisplay: imageURI });
							console.log("eligible :" + this.state._eligible);

						}


						this.setState({ totalSupply: totalSupplyNFT });

						console.log("Total Supply:" + totalSupplyNFT);
						console.log("Total Supply:" + tokenIDBool);

						console.log("State : " + this.state.imageURIDisplay);


					} catch (err) {
						console.log("err: " + err);

					}

				}


			})();

			//.....................................................................//






			// Legacy providers may only have ethereum.sendAsync
			const chainId = await provider.request({
				method: 'eth_chainId'
			})

		} else {

			// if the provider is not detected, detectEthereumProvider resolves to null
			console.error('Please install MetaMask!');
			alert('A valid provider could not be found!');

		}

	}

	wordsCount = async event => {
		event.preventDefault();
		words();
	}

	plus = async event => {
		event.preventDefault();
		points = points + 1;
		this.setState({ _points: points });



		Axios.post('https://alpha-bunny-nft.herokuapp.com/pointsup', {

			list_wallet: writersWallet

		}).then(() => {
			alert("Successful Insert :" + this.state._walletList);
		});
		console.log("Data Inserted");



	}

	minus = async event => {
		event.preventDefault();
		points = points - 1;
		this.setState({ _points: points });

		console.log("Writer" + writersWallet);
		console.log("WriterState" + writersWallet);


		Axios.post('https://alpha-bunny-nft.herokuapp.com/pointsdown', {

			list_wallet: writersWallet

		}).then(() => {
			alert("Successful Insert");
		});
		console.log("Data Inserted");
	}

	async componentDidMount() {

		let currentUrl = window.location.href;
		console.log("currentUrl" + currentUrl);

		let arr = currentUrl.split("=");
		console.log("arr:" + arr[1]);
		successMessage = arr[1];
		this.setState({ _successMessage: successMessage });

		Axios.get("https://alpha-bunny-nft.herokuapp.com/fetchBunny").then((response) => {

			if (response.data !== undefined) {
				let notice_board_array = [];
				notice_board_array = response.data;
				this.setState({ notice_board_Array: notice_board_array });
			}
		});


		Axios.get("https://alpha-bunny-nft.herokuapp.com/fetchPoints").then((response) => {

			if (response.data !== undefined) {
				points_table_array = response.data;
				this.setState({ points_table_Array: points_table_array });
				//console.log("points_table_Array :" + this.state.points_table_Array[0].writersWallet);
			}
		});




	}


	render() {

		return (

			<div class="allWrap">
				<div class="headerPicAndTitle">
					<div class="logoDiv"><img class="logo" src={logo} /></div>
					<div class="nb">Notice Board</div>
				</div>
				<div class="ownersSection">

					{this.state.notice_board_Array.map((element) => {
						match = 0;
						{
							this.state._votedAddresses.map((va) => {
								if (element.wallet_address == va) {
									console.log("va :" + va);
									match = 1;
								}
							})
						}

						return (
							<div class="clientZoneMain">
								<div class="clientZone">
									<div class="picAndStory">
										<div class="nftPic"><img class="nftImg" src={element.img_url} /></div>
										<div class="picAndName">
											<div class="writerDiv"><img class="writer" src={signature} /></div>
											<div class="clientName">{element.owner_name}</div>
										</div>



										<div class="voteArea">
											<form action="https://alpha-bunny-nft.herokuapp.com/pointsup" method="post" type="multipart/form-data">

												<input type="hidden" value={this.state.walletAddress} id="votingWallet1" name="votingWallet1" />
												<input type="hidden" value={element.wallet_address} id="writerWallet1" name="writerWallet1" />




												<div class="rabitIconDiv">
													{
														match === 0 && this.state.walletAddress !== '' ?

															(<div>
																<button class="voteBtn" type="submit" >Vote</button>
															</div>) :

															(<div>

																<button class="voteBtn2" type="submit" disabled>Vote</button></div>)
													}

													<div class="clientName2">{element.points}</div>

												</div>

											</form>

										</div>

									</div>

								</div>
								<div class="clientStory">{element.story}</div>
								{this.state._successMessage == "success" ? (
									<div class="formMain">
										<div class="loginFormNote">
											<div class="checkedPicDiv"><img class="checkedPic" src={checked} /></div>
											<div class="noteSuccess">Sumbmited Successfully</div>
											<button class="continue"><a href="https://nboard.belladonnabunny.com">Continue</a></button>
										</div>

									</div>) : null}

								{this.state.points_table_Array == "success" ? (
									<div class="formMain">
										<div class="loginFormNote">
											<div class="checkedPicDiv"><img class="checkedPic" src={checked} /></div>
											<div class="noteSuccess">Rating Added Successfully</div>

										</div>
										<form onSubmit={this.closeWindow}>
											<button class="closeNote">✖</button>
										</form>
									</div>) : null}
							</div>
						);

					})}</div>

				<div class="main">
					<div class="line"></div>
					{this.state.walletAddress === '' ?

						(<div class="centerMM">

							<form onSubmit={this.walletbtn}>
								<div class="tab1Div"><button class="tab1"><img class="mM" src={mM} /></button></div>
							</form>
						</div>) : (<div class="right"><button class="wallet2" ><b>
							{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</b></button></div>)}


					{this.state.walletAddress !== '' && this.state._alreadySubmitted == false && this.state._eligible > 0 ?
						(<form class="form" action="https://alpha-bunny-nft.herokuapp.com/profile" method="post" type="multipart/form-data">
							<div class="block_container">
								<input type="hidden" value={this.state.walletAddress} name="walletAddress" />
								<input type="hidden" value={this.state.imageURIDisplay} name="img_url_db" />


								<div class="user">
									<div><img class="userPic" src={user} /></div>
									<input type="text" class="form__input" name="yourName" maxlength="6" placeholder="Your name" />
								</div>

								<textarea onKeyUp={() => words()} id="w3review" name="story" rows="10" cols="50" class="comment" placeholder="You can write your story here...">
								</textarea>
								<div class="countWords">
									<p>  Word Count :
										<div id="show"> 0</div>
									</p>
								</div>
								<span id="words-counter"></span>


								<div>
									<button class="button" type="submit">Submit</button>
								</div>
							</div>
						</form>) : null
					}

				</div>
			</div>)
	}
}

export default App;